import React from 'react'
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import "./css/Admin.css"
export const View = ({ users, deleteUser }) => {

    return users.map((user, index) => (

        <tr key={index.toString()}>
            <td>{index.toString()}</td>
            <td className='tdata'>{user.email}</td>
            <td className="tdata">{user.workspace}</td>
            <td className="tdata">{user.sheet}</td>
            <td className='delete-btn' onClick={() => deleteUser(user.email)}>
                <IconButton color="secondary" aria-label="add an alarm">
                    <DeleteIcon />
                </IconButton>
            </td>
        </tr>

    ))
}