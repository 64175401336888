import React, { useEffect, useState } from 'react'
import "../footer/Footer.css"
// import ScrollButton from 'react-scroll-button'
import ScrollToTop from "react-scroll-to-top";
import { Link } from 'react-router-dom';
export default function Footer() {
    return (
        <footer class="footer-section">
            <div class="container">
                <div class="footer-content pt-1">
                    <div class="row">
                        <div class="col-xl-4 col-lg-4 mb-50">
                            <div class="footer-widget">
                                <div class="footer-logo">
                                    <Link to="/">
                                        <img class="transparent-logo dark-scheme-logo img-fluid"
                                            src="https://acquiretek.com/wp-content/uploads/2020/09/logo-acquiretek-over-dark-194x62-1.png"
                                            alt="Acquiretek" />
                                    </Link>
                                    <p className='p'></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 mb-30">
                            <div class="footer-widget">
                                <div class="footer-widget-heading">
                                    <h3>Useful Links</h3>
                                </div>
                                <p className='p'>
                                    We simplify mergers and acquisitions, working alongside you to deliver a focused and complete integration.
                                </p>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 mb-50">
                            <div class="footer-widget">
                                <div class="footer-widget-heading">
                                    <h3>Contact Us</h3>
                                </div>
                                <div class="footer-text mb-25">
                                    <p className='p'>1720 Windward Concourse, Suite 100<br></br>
                                        Alpharetta, GA 30005<br></br>
                                        Inquiries@AcquireTek.com<br></br>
                                        770-450-4268</p>
                                    {/* <a href='fideliswaweru19@gmail.com'>Write us an email...</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="copyright-area">
                    <div class="row">
                        <div class=" col-lg-12 text-center text-lg-left">
                            <div class="copyright-text">
                                <p>Copyright &copy; AcquireTek 2022. All Rights Reserved. <a href="https://acquiretek.com/"></a></p>
                            </div>
                            <ScrollToTop/>
                        </div>
                    </div>
            </div>
        </footer>
    )
}
