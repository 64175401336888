import "./Sidebar.css";
import { Link, NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "../../../node_modules/react-circular-progressbar/dist/styles.css";
import { withTheme } from "@material-ui/core";

export default function Sidebar({ percentages, items, setActiveSection }) {
  return (
    <div className="sidebarstyle">
      <div className="wrap-style">
        <h3 className="sidebarTitle">Sections</h3>
        <div>
          <ul className="sidebarList">
            {items &&
              Object.keys(items)?.map((sect, i) => {
                return (
                  <SectionItem
                    key={i}
                    setActiveSection={setActiveSection}
                    sect={sect}
                    percentages={percentages}
                    section={items[sect]}
                  />
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
}
const SectionItem = ({ setActiveSection, sect, percentages, section }) => {
  // console.log(percentages);
  const [percentage, setPercentage] = useState(0);

  let activeStyle = {
    backgroundColor: '#4682B4',
    color: 'white',
    borderRadius: ".2rem",
  };

  useEffect(() => {
    // console.log(percentages);
    if (percentages?.length > 0) {
      const found = percentages.find((p) => Object.keys(p)[0] === sect)[sect];
      // console.log(found);
      setPercentage(found);
    }
  }, [percentages, sect]);

  //  console.log({percentage, sect});
  return (
    <li className="btn-section">
      <NavLink
        to={`/demo/${sect}`}
        style={({ isActive }) =>
              isActive ? activeStyle : undefined
            }
        className="sidebarListItem"
      >
        {sect}
      </NavLink>
      <div class="progress_bar" style={{ width: "2.5rem", height: "2.5rem" }}>
        <CircularProgressbar
          className="circularProgressbar"
          variant="success"
          strokeWidth={43}
          background
          backgroundPadding={7}
          value={percentage}
          styles={{
            path: {
              strokeLinecap: "butt",
            },
            trail: {
              stroke: "transparent",
            },
          }}
        />
      </div>
    </li>
  );
};
