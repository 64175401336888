import React, { useEffect, useState } from "react";
import * as ReactBootstrap from "react-bootstrap";
import "./Modal.css";
import pdf from "../../images/Demo.pdf";
import Sidebar from "../sidebar/Sidebar";
import axios from "axios";
import { Navigate, useNavigate } from "react-router";
import { Grid } from "@material-ui/core";

const card = {
  display: "flex",
};

function Modal({ activeSection, setCheckedRows, sect, setActiveSection }) {
  const navigate = useNavigate();
  const [section, setSection] = useState([]);
  const [percentages, setPercentages] = useState([]);
  const [activeSect, setActiveSect] = useState([]);

  useEffect(() => {
    const sectionKeys = Object.keys(section);
    // console.log({ sectionKeys });
    const percentages = sectionKeys.map((key) => {
      const section_ = section[key];
      const sectCount = section_?.length;
      const checkedRows_ = [];
      section_?.forEach((data) => {
        const row_ID = data["row"].rowId;
        if (data[0].value) {
          checkedRows_.push({
            value: data[0].value,
            rowId: row_ID,
            columnId: data[0].columnId,
          });
        } else if (data[1].value) {
          checkedRows_.push({
            value: data[1].value,
            rowId: row_ID,
            columnId: data[1].columnId,
          });
        } else if (data[2].value) {
          checkedRows_.push({
            value: data[2].value,
            rowId: row_ID,
            columnId: data[2].columnId,
          });
        }
      });
      if (activeSection === key) {
        setCheckedRows(checkedRows_);
      }
      const data_count = checkedRows_?.length;
      const prog = (data_count / sectCount) * 100;
      return { [key]: prog };
    });
    setPercentages(percentages);
  }, [section, activeSection, setCheckedRows]);

  // console.log(percentages);

  useEffect(() => {
    const getData = () => {
      axios
        .get("https://lbthap.acquiretrak.com/api/test/", {
          headers: {},
        })
        .then((res) => {
          const data = res.data;
          setSection(data);
          // console.log(section)
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getData();
  }, [section]);

  return (
    // <div className="modalBackground">
    // <div className="modalContainer">
    <Grid container style={card}  className="modal-style" justifyContent="center">
      <Grid align="center" justifyContent="center">
        {/* <div className="body"> */}
          <Sidebar
            setActiveSection={setActiveSection}
            items={section}
            setCheckedRows={setCheckedRows}
            percentages={percentages}
          />
        {/* </div> */}
      </Grid>
    </Grid>
  );
}

export default Modal;
