import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom"
import queryString from "query-string"
import axios from 'axios'
import { Avatar, Button, Checkbox, FormControlLabel, Grid, Paper, TextField, Typography } from '@material-ui/core'
import Background from '../images/bg00.png'
import { Form } from 'react-bootstrap'

const loginCard = {
    padding: 20,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: '4%',
    marginBottom: '4%',
    backgroundColor: "rgb(230, 242, 255)",
};
const avatarStyle = { 
    background: "blue" };
const btnStyle = { 
    padding: 10 
};
const styleTypo = { 
    padding: 20 
};
const bgStyling = {
    // marginTop: '0',
    display: 'flex',
    width: '100%',
    padding: "unset",
};
const styleForm = {
    marginTop: '18px',
    marginBottom: "20px"
}
const card = {
    display: "flex"
}
const LoginPage = (props) => {

    const [emailError, setEmailError] = useState('')
    const [loading, setLoading] = useState(false)
    const { search } = useLocation();
    const [data, setData] = useState({
        email: "",
    })
    const navigate = useNavigate()

    const { email } = data;

    const changeHandler = (e) => {
        setData({ ...data, [e.target.name]: [e.target.value] });
    }

    const submitHandler = (e) => {
        e.preventDefault()
        axios.post("https://lbthap.acquiretrak.com/api/login/", {
            email,
        }).then(res => {
            console.log(res.data)
        }).catch(err => {
            console.log(err);
        })
        navigate("/demo/section")
    }
    return (
        <Grid style={bgStyling}>
            <Paper style={loginCard}>
                <Grid container style={card} justifyContent="center">
                    <Grid align="center" justifyContent="center">
                        <Avatar style={avatarStyle}>P</Avatar>
                        <h2>SIGN IN</h2>
                        <></>
                        <form onSubmit={submitHandler} >
                            <TextField
                                label="Email"
                                type="email"
                                variant="outlined"
                                name="email"
                                onChange={changeHandler}
                                value={email}
                                style={styleForm}
                                fullWidth
                                required
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                style={btnStyle}
                                type="submit"
                                // onClick={handleLogin}
                                // fullWidth
                                disabled={loading}
                            >
                                {
                                    loading ? "Loading...." : " SIGN IN"
                                }

                            </Button>
                        </form>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}

export default LoginPage;
