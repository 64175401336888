import React, { useState } from "react";
import { useNavigate } from "react-router";
import "./Topbar.css";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
export default function Topbar() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [signedIn, setSignedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  // const []
  const navigate = useNavigate();
  const onLogout = () => {
    setSignedIn(false);
    navigate("/demo/login");
  };
  return (
    <nav class="navbar navbar-expand-lg">
      {/* <div class="container"> */}
      <div className="navbar-nav  mb-2 mb-lg-0">
          <Link to="/demo">
            <img
              class="transparent-logo dark-scheme-logo"
              src="https://acquiretek.com/wp-content/uploads/2020/09/logo-acquiretek-over-dark-194x62-1.png"
              alt="Acquiretek"
            />
          </Link>
        </div>
      <button
        class="navbar-toggler text-light"
        type="button"
        data-mdb-toggle="collapse"
        data-mdb-target="#navbarRightAlignExample"
        aria-controls="navbarRightAlignExample"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="fas fa-bars"></i>
      </button>
      <div class="topbar-css collapse navbar-collapse" id="navbarRightAlignExample">
        {/* <!-- Left links --> */}

        <div className="navbar-nav me-0 mb-2 mb-lg-0 name text-white-50">
          <h3>
          Technology Health Assessment
          </h3>
        </div>

        <div class="navbar-nav ms-auto mb-2 mb-lg-0 text-info">
          <button onClick={onLogout} class="btn btn-info me-3">
            {/* {signedIn === true ? "Login" : "Logout"} */}
            Logout
          </button>
        </div>
      </div>
      {/* </div> */}
    </nav>
  );
}
