import React, { useEffect, useState } from 'react'
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom"
import queryString from "query-string"
import axios from 'axios'
import { Avatar, Button, Checkbox, FormControlLabel, Grid, Paper, TextField, Typography } from '@material-ui/core'
import Background from '../images/bg00.png'
import { Form } from 'react-bootstrap'
import "./css/Login.css"
import { useParams } from 'react-router-dom'
import { Label } from '@material-ui/icons'

const loginCard = {
    padding: 20,
    margin: "auto",
    marginTop: '25%',
    marginBottom: '4%',
    backgroundColor: "rgb(230, 242, 255)",
};
const avatarStyle = { background: "blue" };
const btnStyle = { padding: 10 };
const styleTypo = { padding: 20 };
const bgStyling = {
    marginTop: '0',
    // backgroundImage: `url(${Background})`,
    display: 'flex',
    width: '100%',
    padding: "unset",
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    overflow: 'auto',
};
const styleForm = {
    marginTop: '18px',
    marginBottom: "20px"
}
const card = {
    display: "flex"
}
const LoginPage = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({
            email: ""
    })
    const [error, setError] = useState("");

    const { email } = data;
    const changeHandler = (e) => {
        setData({ ...data, [e.target.name]: [e.target.value] })
    }
    const {emailId} = useParams();

    const submitHandler = (e) => {
        e.preventDefault()
        axios.post("http://127.0.0.1:8000/api/adminlogin/", {
            email
        }).then(res => {
            const data1 = res.data;
            console.log(data1)
            if(data1.role === "admin" && data1.success === "true")
            {
                navigate(`/auth/${data1.email}`, {state: {email: {email}}})
            }else{
                setError("Invalid Email!!");
                setLoading(false)
            }
            // console.log(email)
        }).catch(err => {
            console.log(err);
        })
        setLoading(true)
    }
    return (
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-5 items justify-content-center">
                    <Grid style={loginCard} className="loginCard">
                            <Grid container style={card} justifyContent="center">
                                <Grid align="center" justifyContent="center">
                                    <Avatar style={avatarStyle}>P</Avatar>
                                    <h2>LOGIN</h2>
                                    <p>Sign in to your account</p>
                                    <></>
                                    {error?<label class="text-danger">{error}</label>:null}
                                    <Form onSubmit={submitHandler}>
                                        <TextField
                                            // id="outlined-basic"
                                            label="Email"
                                            type="email"
                                            // variant="outlined"
                                            variant="filled"
                                            name="email"
                                            onChange={changeHandler}
                                            value={email}
                                            style={styleForm}
                                            fullWidth
                                            required
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={btnStyle}
                                            type="submit"
                                            // onClick={handleLogin}
                                            // fullWidth
                                            // disabled={loading}
                                        >
                                           {loading === true ? "Loading..." : "SUBMIT"} 
                                        </Button>
                                    </Form>
                                </Grid>
                            </Grid>
                    </Grid>
                </div>
                <div class="col-5 left ">
                    <div className="d-flex justify-content-center">
                        <img className="image" src="https://acquiretek.com/wp-content/uploads/2020/09/logo-acquiretek-over-dark-194x62-1.png" alt="Acquiretek" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginPage;
