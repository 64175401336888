import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import "./HomePage.css";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Sidebar from "./sidebar/Sidebar";
import Assessment from "../images/Demo.pdf";
import "bootstrap/dist/css/bootstrap.min.css";
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
const useStyles = makeStyles({
  root: {
    maxWidth: 1000,
    flexGrow: 1,
  },
});

export default function Homepage() {
  const [percentages, setPercentages] = useState([]);
  const [section, setSection] = useState([]);
  const [loading, setLoading] = useState("");
  const [data, setData] = useState([]);
  const [checked, setChecked] = useState(false);
  const [activeSection, setActiveSection] = useState(
    "Strategy General Management"
  );
  const [checkedRows, setCheckedRows] = useState([]);

  const handleChange = (value, col_key, row_id) => {
    const newdata = checkedRows.map((item) => {
      // console.log(item);
      if (item.rowId === row_id) {
        return { value: value, rowId: row_id, columnId: col_key };
      }
      return item;
    });
    // console.log([...newdata]);
    // setCheckedRows(newdata)
    const arraywithdeuplicates = [
      ...newdata,
      { value: value, rowId: row_id, columnId: col_key },
    ];
    // console.log(checkedRows);
    //removing dublicate rows
    const newData = arraywithdeuplicates.reduce((finalArray, current) => {
      const obj = finalArray.find((items) => items.rowId === current.rowId);
      // console.log(obj);
      if (obj) {
        return finalArray;
      }
      return finalArray.concat([current]);
    }, []);
    setCheckedRows(newData);
    setData(newData);
    // console.log(newData);
    const data_count = newData.length;
    // console.log(data_count);
    const prog = (data_count / section[activeSection]?.length) * 100;
    const newPercentages = percentages.map((percentage) => {
      if (Object.keys(percentage)[0] === activeSection)
        return { [activeSection]: prog };
      return percentage;
    });
    console.log({ newPercentages });
    setPercentages(newPercentages);
    // console.log(prog);
    // console.log(data);
  };

  //handleclick
  const handleClick = (index) => {
    axios
      .put("https://lbthap.acquiretrak.com/api/update/", {
        data,
      })
      .then((res) => {
        setData(res.data);
        setChecked("");
        // window.location.reload(true);
        // console.log(data);
      })
      .catch((res) => {
        if (res.error) {
          console.log(res.error);
          console.log(res.error.status);
          console.log(res.error.headers);
        }
      });
  };

  const checkIfIsChecked = (rowId, columnId) => {
    if (
      checkedRows.find(
        (item) => item.rowId === rowId && item.columnId === columnId
      )
    ) {
      return true;
    }
    return false;
  };
  const { sectionName } = useParams();

  console.log(sectionName);

  useEffect(() => {
    const getData = () => {
      axios
        .get("https://lbthap.acquiretrak.com/api/test/", {
          headers: {},
        })
        .then((res) => {
          const data = res.data;
          // setActiveSection(Object.keys(data)[0]);
          setSection(data);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    };
    getData();
    setActiveSection(sectionName);
  }, [activeSection, sectionName]);

  useEffect(() => {
    const sectionKeys = Object.keys(section);
    console.log({ sectionKeys });
    const percentages = sectionKeys.map((key) => {
      const section_ = section[key];
      // const selectedSectionData = section[sect];
      // console.log({selectedSectionData})
      const sectCount = section_?.length;
      const checkedRows_ = [];
      section_?.forEach((data) => {
        const row_ID = data["row"].rowId;
        if (data[0].value) {
          checkedRows_.push({
            value: data[0].value,
            rowId: row_ID,
            columnId: data[0].columnId,
          });
        } else if (data[1].value) {
          checkedRows_.push({
            value: data[1].value,
            rowId: row_ID,
            columnId: data[1].columnId,
          });
        } else if (data[2].value) {
          checkedRows_.push({
            value: data[2].value,
            rowId: row_ID,
            columnId: data[2].columnId,
          });
        }
      });
      if (activeSection === key) {
        setCheckedRows(checkedRows_);
      }
      const data_count = checkedRows_?.length;
      // console.log(data_count);
      const prog = (data_count / sectCount) * 100;
      // console.log({prog});
      return { [key]: prog };
    });
    setPercentages(percentages);
  }, [section, activeSection]);
  // console.log(checkedRows);

  return (
    <div className="home_ navbar-expand-lg">
      <button
        class="navbar-toggler text-light"
        type="button"
        data-mdb-toggle="collapse"
        data-mdb-target="#navbarRightAlign"
        aria-controls="navbarRightAlign"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <ArrowDropDownOutlinedIcon className="menu-btn" />
        <Typography variant="body1">SECTIONS</Typography>
        {/* <i class="fas fa-bars menu-btn"></i> */}
      </button>
      <div className="sidebar col" class="collapse navbar-collapse" id="navbarRightAlign">
        <Sidebar
          setActiveSection={setActiveSection}
          items={section}
          percentages={percentages}
        />
      </div>
      <div class="left-homepage col">
        <Grid container className="data">
          <div class="gap-8 " >
            <a
              href="https://app.smartsheet.com/dashboards/6vx3M98gX3fPr4CfJrXHGqvxx2rFQ5XQrf23hpW1"
              target="_blank"
              rel="noreferrer"
            >
              <button class="btn btn-primary pdf-btn" type="button">
                Link to Dashboard
              </button>
            </a>
            <a href={Assessment} target="_blank" rel="noreferrer">
              <button class="btn btn-primary pdf-btn" type="button">
                Link to Assessment PDF
              </button>
            </a>
          </div>
          <Grid container justifyContent="center">
            <div class="section_title">
              <h6 setActiveSection={setActiveSection} items={section}>
                <span className="span_sec">{activeSection}</span>
              </h6>
            </div>
          </Grid>
          <Grid container justifyContent="center" overflow-x="auto">
            <table class="homepage" align-items="center">
              <thead>
                <tr className="header">
                  <th className="implementation">Best Practice Definition</th>
                  <th className="implementation">Fully Implemented</th>
                  <th className="implementation">Partially Implemented</th>
                  <th className="implementation">Not Implemented</th>
                </tr>
              </thead>
              <tbody>
                {section[activeSection]?.map((item, index) => {
                  return (
                    <>
                      <>
                        <tr key={index}>
                          <td className="question">{item?.description}</td>
                          <td className="radio">
                            <input
                              id={item[0]?.columnId}
                              type="radio"
                              checked={checkIfIsChecked(
                                item["row"]?.rowId,
                                item[0]?.columnId
                              )}
                              name={item["row"]?.rowId}
                              onChange={(e) => {
                                handleChange(
                                  e.target.checked,
                                  item[0]?.columnId,
                                  item["row"]?.rowId
                                );
                              }}
                              disabled={
                                item[0]?.value ||
                                item[1]?.value ||
                                item[2]?.value
                                  ? true
                                  : false
                              }
                            />
                          </td>
                          <td className="radio">
                            <input
                              id={item[1]?.columnId}
                              type="radio"
                              checked={checkIfIsChecked(
                                item["row"]?.rowId,
                                item[1]?.columnId
                              )}
                              name={item["row"]?.rowId}
                              onChange={(e) =>
                                handleChange(
                                  e.target.checked,
                                  item[1]?.columnId,
                                  item["row"]?.rowId
                                )
                              }
                              disabled={
                                item[0]?.value ||
                                item[1]?.value ||
                                item[2]?.value
                                  ? true
                                  : false
                              }
                            />
                          </td>
                          <td className="radio">
                            <input
                              id={item[2]?.columnId}
                              type="radio"
                              checked={checkIfIsChecked(
                                item["row"]?.rowId,
                                item[2]?.columnId
                              )}
                              name={item["row"]?.rowId}
                              onChange={(e) =>
                                handleChange(
                                  e.target.checked,
                                  item[2]?.columnId,
                                  item["row"]?.rowId
                                )
                              }
                              disabled={
                                item[0]?.value ||
                                item[1]?.value ||
                                item[2]?.value
                                  ? true
                                  : false
                              }
                            />
                          </td>
                        </tr>
                      </>
                    </>
                  );
                })}
              </tbody>
            </table>
          </Grid>
          <div class="gap-6 col-6 ">
            <button
              class="btn btn-primary save"
              type="submit"
              onClick={handleClick}
            >
              SAVE
            </button>
          </div>
        </Grid>
      </div>
    </div>
  );
}
