import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom"
import queryString from "query-string"
import axios from 'axios'
import { Avatar, Button, Checkbox, FormControlLabel, Grid, Paper, TextField, Typography } from '@material-ui/core'
import Background from '../images/bg00.png'
import { Form } from 'react-bootstrap'
import "./css/Login.css"
import "../Admin/Login"
const loginCard = {
    padding: 20,
    margin: "auto",
    marginTop: '25%',
    marginBottom: '4%',
    backgroundColor: "rgb(230, 242, 255)",
};
const avatarStyle = { background: "blue" };
const btnStyle = { padding: 10 };
const styleTypo = { padding: 20 };
const bgStyling = {
    marginTop: '0',
    // backgroundImage: `url(${Background})`,
    display: 'flex',
    width: '100%',
    padding: "unset",
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    overflow: 'auto',
};
const styleForm = {
    marginTop: '18px',
    marginBottom: "20px"
}
const card = {
    display: "flex"
}
const LoginPage = () => {
    const [pass, setPass] = useState({
        password: ""
    })
    
    const location =  useLocation();
    const {email}  = location.state;
    const [loading, setLoading] = useState(false)
    const user_email = JSON.stringify(email.email)
    const email1 = JSON.parse(user_email)
    // console.log(email1);

    const navigate = useNavigate()
    const { password } = pass;
    const changeHandler = (e) => {
        setPass( {...pass,  [e.target.name]:[e.target.value] })
    }
    const [error, setError] = useState("");

    const submitHandler = (e) => {
        e.preventDefault()
        axios.post("http://127.0.0.1:8000/api/adminauth/", {
            password,
            email1
        }).then(res => {
            const admin = res.data;
            console.log(admin)
            if(admin.success === "true" )
            {
                navigate("/home")
            } else {
                setError("Invalid Credentials!!!");
                setLoading(false)
            }
        }).catch(err => {
            console.log(err)
        })
        setLoading(true)
    }
    return (
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-5 items justify-content-center">
                    <Grid style={loginCard}>
                        <Grid container style={card} justifyContent="center">
                            <Grid align="center" justifyContent="center">
                                <Avatar style={avatarStyle}>P</Avatar>
                                <h2>SIGN IN</h2>
                                <p>Fill in your Password to Proceed</p>
                                <></>
                                {error?<label class="text-danger">{error}</label>:null}
                                <Form onSubmit={submitHandler}>
                                <TextField
                                        label="Email"
                                        type="email"
                                        variant="filled"
                                        name="email"
                                        value={email1}
                                        onChange={changeHandler}
                                        style={styleForm}
                                        fullWidth
                                        required
                                    />
                                    <TextField
                                        label="Password"
                                        type="password"
                                        variant="filled"
                                        name="password"
                                        onChange={changeHandler}
                                        style={styleForm}
                                        fullWidth
                                        required
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={btnStyle}
                                        type="submit"
                                    >
                                        {loading === true ? "Loading..." : "LOGIN"} 

                                    </Button>
                                </Form>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div class="col-5 left ">
                    <div className="d-flex justify-content-center">
                        <img className="image" src="https://acquiretek.com/wp-content/uploads/2020/09/logo-acquiretek-over-dark-194x62-1.png" alt="Acquiretek" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginPage;
