import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useResolvedPath,
} from "react-router-dom";
import queryString from "query-string";
import axios from "axios";
import {
  Avatar,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import Background from "../images/bg00.png";
import DeleteIcon from "@material-ui/icons/Delete";
import { Form } from "react-bootstrap";
import "./css/Admin.css";
import { View } from "./View";
const loginCard = {
  padding: 20,
  margin: "auto",
  marginTop: "4%",
  marginBottom: "4%",
  backgroundColor: "rgb(230, 242, 255)",
};
const avatarStyle = { background: "blue" };
const btnStyle = { padding: 10 };
const styleTypo = { padding: 20 };
const bgStyling = {
  marginTop: "0",
  // backgroundImage: `url(${Background})`,
  display: "flex",
  width: "100%",
  padding: "unset",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  overflow: "auto",
};
const styleForm = {
  marginTop: "18px",
  marginBottom: "20px",
};
const card = {
  display: "flex",
};

// getting the values of local storage
const getDataFromLS = () => {
  const data = localStorage.getItem("users");
  if (data) {
    return JSON.parse(data);
  } else {
    return [];
  }
};
const LoginPage = () => {
  // main array of objects state || users state || users array of objects
  const [users, setUsers] = useState(getDataFromLS());
  const [loading, setLoading] = useState(false);
  // input field states
  const [workspace, setWorkspace] = useState("");
  const [sheet, setSheet] = useState("");
  const [email, setEmail] = useState("");

  // form submit event(add users)
  const submitAdd = (e) => {
    e.preventDefault();
    // creating an object
    let user = {
      workspace,
      sheet,
      email,
    };
    setUsers([...users, user]);
    setWorkspace("");
    setSheet("");
    setEmail("");
  };

  // delete user from LS
  const deleteUser = (email) => {
    const filteredUsers = users.filter((element, index) => {
      return element.email !== email;
    });
    setUsers(filteredUsers);
  };

  // saving data to local storage
  useEffect(() => {
    localStorage.setItem("users", JSON.stringify(users));
  }, [users]);

  // form submit event(submit users)
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("http://127.0.0.1:8000/api/adminpage/", {
        users,
      })
      .then((res) => {
        console.log(res.data);
        console.log(users);
        setUsers([]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(true);
  };

  return (
    // <div class="container">
    //   <div class="row justify-content-center">
    //     <div class="col-8 data-items">
    //       <Grid style={loginCard}>
    <Grid container style={card} justifyContent="center">
      <Grid align="center" justifyContent="center"  className="form-styles">
        <Avatar style={avatarStyle}>P</Avatar>
        <h2>ADD USERS</h2>
        <></>
        <form onSubmit={submitAdd}>
          <TextField
            label="Enter the Workspace Name"
            type="text"
            variant="filled"
            name="workspace"
            onChange={(e) => setWorkspace(e.target.value)}
            value={workspace}
            style={styleForm}
            fullWidth
            required
          />
          <TextField
            label="Enter the Sheet Name"
            type="text"
            variant="filled"
            name="sheet"
            onChange={(e) => setSheet(e.target.value)}
            value={sheet}
            style={styleForm}
            fullWidth
            required
          />
          <TextField
            label="Email"
            type="email"
            variant="filled"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            style={styleForm}
            fullWidth
            required
          />
          <Button
            variant="contained"
            color="primary"
            style={btnStyle}
            type="submit"
          >
            ADD USER
          </Button>
        </form>
        <div class="table-style">
        <table className="display">
          <thead>
            <tr className="trow1">
              <th className="thead1">ID</th>
              <th className="thead2">Email</th>
              <th className="thead2">Workspace</th>
              <th className="thead2">SheetName</th>
              <th className="thead3">action</th>
            </tr>
          </thead>
          <tbody>
            <View users={users} deleteUser={deleteUser} />
          </tbody>
        </table>
        </div>
        <Button
          variant="contained"
          color="primary"
          style={btnStyle}
          onClick={handleSubmit}
          type="submit"
        >
          {loading === true ? "Loading" : "Submit"}
        </Button>
      </Grid>
    </Grid>
    //   </Grid>
    //     </div>
    //   </div>
    // </div>
  );
};

export default LoginPage;
