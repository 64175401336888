import { Route, Routes } from "react-router-dom"
import HomePage from "../src/Pages/HomePage"
import LoginPage from "../src/Pages/LoginPage"
import Admin_login from "./Admin/Login"
import Topbar from '../src/Pages/topbar/Topbar'
import Footer from "./Pages/footer/Footer"
import { Navbar } from "react-bootstrap"
import Admin from "./Admin/Admin"
import GetPass from "./Admin/GetPass"
import Sidebar from "./Pages/sidebar/Sidebar"
import Users from "./Pages/UserList/UserList"
import Modal from "./Pages/modal/Modal"
import "./App.css"
const App = () => {
  return (
    <div>
      <Topbar />
      <div className="App" >        
        <Routes>
          <Route path="/demo/:sectionName" element={<HomePage />} />
          <Route path="/demo/login" element={<LoginPage />} />
          {/* <Route path="/demo/section" element={<Splashscreen />} /> */}
          <Route path="/demo/section" element={<Modal />}/>
          <Route path="/" element={<Admin_login />} />
          <Route path="/auth/:emailId" element={<GetPass />} />
          <Route path="/home" element={<Admin />} />
         </Routes>
      </div>
      <Footer/>
    </div>
  )
}

export default App